<template>
    <v-card min-height="50" class="elevation-2 fill-height" :loading="loadingCards || loading">
        <v-card-title class="card-header page">
            <v-icon color="primary" class="mr-1">fa fa-credit-card</v-icon> {{ (addCard) ? 'Add a Card' : 'Your Cards' }}
        </v-card-title>
        <v-card-text class="px-0 pb-0">
            <v-row no-gutters class="mt-0" v-if="!loadingCards">
                <v-col cols="12" md="12" v-show="addCard">
                    <div class="px-5 pt-2 pb-14">
                        
                        <v-alert class="mt-2" dense type="error" v-if="errors">
                            {{ errors }}
                        </v-alert>
                        
                        <v-text-field :autofocus="!$vuetify.breakpoint.smAndDown" v-show="!loading" :rules="[rules.required]" v-model="name" required label="Name on Credit Card" color="primary" />
                        
                        <div class="mt-1 mb-3" id="card-elements"></div>

                        <v-divider class="mb-5" v-show="!loading" />

                        <v-checkbox color="primary" v-if="!loading" v-model="makeDefault" label="Make default payment source." />

                        <div class="d-flex">
                            <!-- <v-btn v-show="!loading" text outlined @click.native="addCard=false">Cancel</v-btn>
                            <v-spacer />
                            <v-btn v-show="!loading" :disabled="!name.length" color="primary" :loading="saving" class="px-6" @click="verifyPayment()">Save Card</v-btn> -->
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="12" v-show="!addCard">
                    <div class="cards-list">
                        <v-list class="pt-0 pb-0">
                            <template v-for="item in cards">
                                <v-list-item :key="item.id" class="pl-3">
                                    <v-list-item-avatar class="align-self-start align-md-center">
                                        <v-avatar>
                                            <v-icon :color="(cards.length > 1 && defaultCard==item.id) ? 'primary' : 'secondary'">fa fa-credit-card</v-icon>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-medium secondary--text"><span class="text-capitalize">{{ item.card.brand }}</span> ending in <pre class="d-inline primary--text font-weight-bold">{{ item.card.last4 }}</pre></v-list-item-title>
                                        <v-list-item-subtitle class="hidden-md-and-up">
                                            Expires {{ getExpMonth(item.card.exp_month) }}/{{ item.card.exp_year }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle class="hidden-md-and-up pt-2">
                                            <template v-if="cards.length > 1">
                                                <span v-if="defaultCard==item.id"><v-icon small color="success">fa fa-check-circle</v-icon>  Current Payment Source</span>
                                                <span v-else><v-btn outlined small color="success" @click.native="defaultCardItem=item" :loading="defaultingCard==item.id">Use This Card</v-btn></span>
                                            </template>
                                            <template v-else>
                                                <span><v-icon small color="success">fa fa-check-circle</v-icon>  Current Payment Source</span>
                                            </template>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle class="hidden-sm-and-down">
                                            <span class="success--text pr-2" v-if="defaultCard==item.id && cards.length > 1"><v-icon small color="success">fa fa-check-circle</v-icon>  Current Payment Source</span> Expires {{ item.card.exp_month }}/{{ item.card.exp_year }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action v-if="defaultCard!=item.id" class="hidden-sm-and-down">
                                        <v-btn small color="success" outlined @click.native="defaultCardItem=item" :loading="defaultingCard==item.id">Use This Card</v-btn>
                                    </v-list-item-action>
                                    <v-list-item-action v-if="defaultCard!=item.id" class="align-self-start align-md-center">
                                        <v-btn icon @click.native="deleteCard=item" :loading="deletingCard==item.id"><v-icon small color="error">fa fa-trash-alt</v-icon></v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                            <v-list-item class="pl-3" @click="addCard=true">
                                <v-list-item-avatar class="align-self-start align-md-center">
                                    <v-avatar>
                                        <v-icon color="secondary">fa fa-plus-circle</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium subtitle-1 secondary--text">Add another Card</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <!-- <v-icon small color="primary">fa fa-plus-circle</v-icon> -->
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="card-actions cnc-actions mt-1" v-show="addCard">
            <v-btn v-show="!loading" text outlined @click.native="addCard=false">Cancel</v-btn>
            <v-spacer />
            <v-btn v-show="!loading" :disabled="!name.length || !cardReady" color="primary" :loading="saving" class="px-6" @click="verifyPayment()">Save Card</v-btn>
        </v-card-actions>
        <Confirm v-if="deleteCard" title="Remove Credit Card" subtitle="Removing card..." msg="Are you sure you want to remove this card?" @confirmed="removeCard()" @closed="deleteCard=null" />
        <Confirm v-if="defaultCardItem" title="Update Payments" subtitle="Changing payment source..." msg="Are you sure you want to make this card your default payment method?" @confirmed="handleDefaultCard()" @closed="defaultCardItem=null" />
    </v-card>
</template>
<script>
    
import { mapState } from 'vuex'
import Confirm from '@/components/common/Confirm'

export default {
    name: 'UserProfileCards',
    props: {
        defaultCard: {
            required: false,
        }
    },
    components: {
        Confirm
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        addCard: false,
        defaultCardItem: null,
        defaultingCard: null,
        loading: false,
        loadingCards: true,
        goingToPortal: false,
        cardReady: false,
        cards: [],
        cardElement: null,
        deleteCard: null,
        deletingCard: null,
        errors: null,
        makeDefault: true,
        intent: null,
        name: '',
        paymentId: null,
        rules: {
            required: value => !!value || 'Required.',
        },
        saving: false,
        stripe: null,
        source: null,
        stripeKey: 'pk_test_51IaZFNBhGwpEk6Y0fARagLQ08KL8v5iY4MRunKur0nC4kprp78LAAQMuoq5zvrlKuHHcAI6bwGGyhUdLPQi6CMDt00DQG3w5Tg'
    }),
    methods: {
        initData(){
            this.ready = true;
            this.getCards();
        },
        getExpMonth(month){
            let thisMonth = '' + month;
            if(thisMonth.length == 1){
                return '0' + month;
            }
            return month;
        },
        async verifyPayment(){
            this.errors = null;
            const { setupIntent, error } = await this.stripe.confirmCardSetup(
                this.intent.client_secret, {
                    payment_method: {
                        card: this.cardElement,
                        billing_details: { name: this.name }
                    }
                }
            );

            if (error) {
                // Display "error.message" to the user...
                this.errors = error.message;
            } else {
                // The card has been verified successfully...
                this.paymentId = setupIntent.payment_method;
                this.saveCard();
            }
        },
        saveCard(){
            this.saving = true;
            let request = {
                endpoint: '/addcard', 
                method: 'post',
                data: {
                    paymentMethodId: this.paymentId,
                    default: this.makeDefault
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your new card has been added.'
                    });
                    this.$appFn.trackEvent({action: 'add_payment_info', label: 'Card added.'});
                }
            }).catch(()=>{
            }).finally(()=>{
                this.saving = false;
                this.addCard = false;
                if(this.makeDefault){
                    this.$emit('defaultchange');
                }
                this.getCards();
            });
        },
        removeCard(){
            this.deletingCard = this.deleteCard.id;
            let request = {
                endpoint: '/removecard', 
                method: 'post',
                data: {
                    paymentMethodId: this.deleteCard.id,
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your card has been removed.'
                    });
                    this.$appFn.trackEvent({action: 'add_payment_info', label: 'Card removed.'});
                }
            }).catch(()=>{
            }).finally(()=>{
                this.deletingCard = null;
                this.getCards();
            });
        },
        handleDefaultCard(){
            this.defaultingCard = this.defaultCardItem.id;
            let request = {
                endpoint: '/defaultcard', 
                method: 'post',
                data: {
                    paymentMethodId: this.defaultCardItem.id,
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your payment source has been updatd.'
                    });
                    this.$appFn.trackEvent({action: 'add_payment_info', label: 'Default card changed.'});
                }
            }).catch(()=>{
            }).finally(()=>{
                this.defaultingCard = null;
                this.$emit('defaultchange');
            });
        },
        initStripe(){
            this.stripe = new window.Stripe(this.stripeKey);
            const elements = this.stripe.elements();
            this.cardElement = elements.create('card',{
                iconStyle: 'solid',
                style: {
                base: {
                    iconColor: '#e2195f',
                    color: '#000',
                    fontWeight: 400,
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    borderBottom: '1px solid #ccc',
                    ':-webkit-autofill': {
                    color: '#e2195f',
                    },
                    '::placeholder': {
                    color: '#333',
                    },
                },
                invalid: {
                    iconColor: '#da3b4b',
                    color: '#da3b4b',
                },
                },
            });
            this.cardElement.mount('#card-elements');
            this.cardElement.on('change', (event) => {
                if (event.complete) {
                    // enable payment button
                    this.cardReady = true;
                } else if (event.error) {
                    this.cardReady = false;
                }
            });
        },
        getIntent(){ 
            this.loading = true;
            let request = {
                endpoint: '/getstripeintent', 
                method: 'get',
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.intent = res.data.intent;
                    // console.log(this.intent);
                    this.initStripe();
                }
            }).catch(()=>{
            }).finally(()=>{
                this.loading = false;
            });
        },
        getCards(){
			this.loadingCards = true;
            let request = {
                endpoint: '/cards', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
					this.cards = res.data;
				}
            }).finally(()=>{
                this.loadingCards = false;
            });
		},
    },
    mounted(){
		this.initData();
	},
    watch: {
        addCard(){
            this.errors = null;
            if(this.addCard){
                this.getIntent();
            }
        }
    }
}
</script>