<template>
    <div>
        <v-toolbar class="sub-bar">
            <v-toolbar-title>
                <v-icon>fa fa-box</v-icon> Your Subscription
            </v-toolbar-title>
        </v-toolbar>
        <v-row class="sub-cont">
            <v-col cols="3" class="hidden-sm-and-down pr-0">
                <LeftNav />
            </v-col>
            <v-col cols="12" md="9" class="pt-lg-1">
                <v-row :dense="$vuetify.breakpoint.lgAndUp">
                    <v-col cols="12" md="12" lg="6" class="pt-3">
                        <Subscription @default="defaultCard=$event" />
                    </v-col>
                    <v-col cols="12" md="12" lg="6" class="pt-1 pt-md-3">
                        <Cards :defaultCard="defaultCard" @defaultchange="updateDefaultCard()" />
                    </v-col>
                    <v-col cols="12" md="12" lg="12" class="pt-1 pt-md-2">
                        <Invoices />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    
import { mapState } from 'vuex'
import Subscription from '@/components/user/profile/Subscription'
import Cards from '@/components/user/profile/Cards'
import Invoices from '@/components/user/profile/Invoices'
import LeftNav from '@/components/ui/LeftNav';

export default {
    name: 'UserProfile',
    components: {
        Cards,
        Invoices,
        Subscription,
        LeftNav
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        defaultCard: null,
        ready: false,
    }),
    methods: {
        initData(){
            this.$appFn.setTitle("Your Subscription - I'm Okay", true);
            this.$appFn.trackPageView('Your Subscription');
        },
        updateDefaultCard(){
            // this.loading = true;
            let request = {
                endpoint: '/subscription', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.defaultCard = res.data.payment_method.id;
				}
            }).finally(()=>{
                // this.loading = false;
            });
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>