<template>
    <v-card class="elevation-2 fill-height" min-height="50" :loading="loading || loadingPromo">
        <v-card-title class="card-header page">
            <v-icon color="primary" class="mr-1">fa fa-bullseye</v-icon> {{ (addPromo) ? 'Apply a Promo Code' : 'Subscription Status' }}
        </v-card-title>
        <v-card-text class="px-0 pb-0">
            <!-- <v-alert class="mt-2" dense type="error" v-if="errors">
                {{ errors }}
            </v-alert> -->
            <v-row no-gutters class="mt-0" v-if="!loading">
                <v-col cols="12" md="12" v-show="!addPromo">
                    <v-list class="pt-0 pb-0">
                        <v-list-item v-if="subscription.subscription.status == 'trialing'" class="pl-3">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="primary">fa fa-award</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium primary--text">In Free Trial Period</v-list-item-title>
                                <v-list-item-subtitle>Free Trial ends {{ $moment.unix(subscription.subscription.trial_end).tz(user.timezone).format('MMMM Do') }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="pl-3" v-if="!user.sub_ends_at">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="secondary">fa fa-calendar-check</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">Next Billing: {{ $moment.unix(subscription.subscription.current_period_end).tz(user.timezone).format('MMMM Do YYYY') }}</v-list-item-title>
                                <v-list-item-subtitle>Subscription began {{ $moment.unix(subscription.subscription.start_date).tz(user.timezone).format('MMMM Do YYYY') }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="pl-3" v-if="user.sub_ends_at">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="secondary">fa fa-calendar-times</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium error--text">Subscription Ends on {{ $moment(user.sub_ends_at).tz(user.timezone).format('MMMM Do YYYY') }}</v-list-item-title>
                                <v-list-item-subtitle>Subscription began on {{ $moment.unix(subscription.subscription.start_date).tz(user.timezone).format('MMMM Do YYYY') }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- <v-list-item class="hidden" @click="manageCards=true">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="primary">fa fa-credit-card</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text"><span class="text-capitalize">{{ subscription.payment_method.card.brand }}</span> ending in <code>{{ subscription.payment_method.card.last4 }}</code></v-list-item-title>
                                <v-list-item-subtitle>Current payment method.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item> -->
                        <v-list-item @click="resume=true" v-if="user.sub_ends_at" class="pl-3">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="success">fa fa-sync</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium success--text">Resume Your Subscription</v-list-item-title>
                                <v-list-item-subtitle>Undo cancellation and keep your account open.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="$router.push({ name: 'account', params: {close:true}})" class="pl-3">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="secondary">fa fa-times-circle</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">Close Your Account</v-list-item-title>
                                <v-list-item-subtitle class="hidden-sm-and-down">Cancel your subscription and erase all your data.</v-list-item-subtitle>
                                <v-list-item-subtitle class="hidden-md-and-up">Cancel subscription and delete data.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small>fa fa-share</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="!subscription.subscription.discount" @click="addPromo=true" class="pl-3">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="secondary" class="pl-1">fa fa-tag</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">Apply a Promo Code</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else class="pl-3" @click="removePromo=true">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="secondary" class="pl-1">fa fa-tag</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">Promo Applied: {{ subscription.subscription.discount.coupon.name }}</v-list-item-title>
                                <v-list-item-subtitle><span class="hidden-sm-and-down">Monthly</span> Discount: {{ getCouponAmount() }} &bull; Expires: {{ getCouponExpires() }} </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small>fa fa-times-circle</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <!-- <p class="subtitle-1">
                        To cancel, renew, or update your payment
                    </p>
                    <v-btn color="primary" @click.native="goToPortal()" :loading="goingToPortal">Manage Subscription</v-btn> -->
                </v-col>
                <v-col cols="12" md="12" v-show="addPromo">
                    <div class="px-5 pt-3 pb-14">
                        
                        <v-alert class="mt-2 subtitle-2 lh-13" dense type="error" v-if="errors">
                            {{ errors }}
                        </v-alert>
                        
                        <v-text-field class="mt-md-4" :autofocus="!$vuetify.breakpoint.smAndDown" :rules="[rules.required]" v-model="promoCode" required label="Promo Code:" placeholder="Ex: CYBERMONDAY20" color="primary" />

                    </div>
                </v-col>
                <!-- <v-col cols="12" md="12">
                    <v-divider />
                    <p class="subtitle-1">
                        Invoices:
                    </p>
                    <v-btn color="primary" @click.native="goToPortal()" :loading="goingToPortal">Manage Subscription</v-btn>
                </v-col> -->
            </v-row>
        </v-card-text>
        <!-- <v-expand-transition>
            <v-card
                v-if="manageCards"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
            >
                <v-card-text class="pa-0">
                    <Cards @close="manageCards=false" @defaultchange="initData()" :defaultCard="subscription.payment_method.id" />
                </v-card-text>
                <v-card-actions class="pt-0 hidden">
                    <v-btn
                        text
                        absolute
                        bottom
                        color="primary accent-4"
                        @click="manageCards = false"
                    >
                        Go Back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition> -->
        <v-card-actions class="card-actions cnc-actions mt-1" v-show="addPromo">
            <v-btn v-show="!loading" text outlined @click.native="addPromo=false">Cancel</v-btn>
            <v-spacer />
            <v-btn v-show="!loading" :disabled="!promoCode.length" color="primary" :loading="loadingPromo" class="px-6" @click="applyPromo()">Apply Promo Code</v-btn>
        </v-card-actions>
        <Confirm v-if="resume" title="Resume Your Subscription" subtitle="Are you sure you want to resume your subscription?" confirmBtnText="Resume Subscription" @confirmed="handleResume()" @closed="resume=false" />
        <Confirm v-if="removePromo" title="Remove Discount" subtitle="Are you sure you want to remove your promo code?" msg="This action will take place immediately and reflect on your next invoice." confirmBtnText="Remove Promo Code" @confirmed="handleRemovePromo()" @closed="removePromo=false" />
    </v-card>
</template>
<script>
    
import { mapState } from 'vuex'

export default {
    name: 'UserProfileSubscription',
    props: {
    },
    components: {
        Confirm: () => import('@/components/common/Confirm'),
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        addPromo: false,
        errors: null,
        loading: true,
        loadingPromo: false,
        manageCards: false,
        goingToPortal: false,
        promoCode: '',
        removePromo: false,
        resume: false,
        subscription: null,
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
    methods: {
        initData(){
            this.ready = true;
            this.getSubscription();
            if(location.href.indexOf('canceled') !== -1){
                // reload subscription
                this.$eventHub.$emit('refreshUser');
                //setTimeout(() => { this.getSubscription() }, 500);
            }
        },
        getCouponAmount(){
            if(this.subscription.subscription.discount.coupon.amount_off){
                let amt = this.subscription.subscription.discount.coupon.amount_off / 100;
                return '$' + amt.toFixed(2);
            }else{
                return this.subscription.subscription.discount.coupon.percent_off + '%';
            }
        },
        getCouponExpires(){
            if(this.subscription.subscription.discount.end){
                return this.$moment.unix(this.subscription.subscription.discount.end).tz(this.user.timezone).format('MM/YYYY');
            }else{
                return 'Never';
            }
        },
        getSubscription(){
			this.loading = true;
            let request = {
                endpoint: '/subscription', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
					this.subscription = res.data;
                    // console.log(this.subscription);
                    this.$emit('default', this.subscription.payment_method.id);
				}
            }).finally(()=>{
                this.loading = false;
            });
		},
        handleResume(){
			this.loading = true;
            let request = {
                endpoint: '/subscription/resume', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your subscription has been resumed!'
                    });
                    this.$appFn.trackEvent({ action: 'user_setting_update', options: {
                        action_name: 'Subscription: Subscription resumed'
                    }});
                    this.$eventHub.$emit('refreshUser');
				}else{
                    // console.log(res.data);
                }
            }).finally(()=>{
                this.getSubscription();
            });
		},
        goToPortal(){
			if(!this.user.stripe_id){
				this.$router.push('/app/subscribe');
				return;
			}
			this.goingToPortal = true;
            let request = {
                endpoint: '/portalurl', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
					location.href = res.data.url;
				}
            }).finally(()=>{
                this.goingToPortal = false;
            });
		},
        applyPromo(){
            this.errors = null;
            this.loadingPromo = true;
            let request = {
                endpoint: '/subscription/promo', 
                method: 'post',
                data: {
                    'promo': this.promoCode
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.getSubscription();
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your promo has been applied!'
                    });
                    this.$appFn.trackEvent({action: 'select_promotion', options: {
                        promotion_id: this.promoCode,
                        promotion_name: this.promoCode
                    }});
                    this.addPromo = false;
                }else{
                    this.errors = res.data;
                }
            }).finally(()=>{
                this.loadingPromo = false;
            });
        },
        handleRemovePromo(){
            let request = {
                endpoint: '/subscription/promo', 
                method: 'delete',
                data: {
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.getSubscription();
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your promo has been removed!'
                    });
                    this.removePromo = false;
                    this.$appFn.trackEvent({action: 'select_promotion', options: {
                        promotion_name: 'Removed Promo'
                    }});
                }else{
                    this.$eventHub.$emit('snackBar', {
                        color: 'error',
                        text: 'Something went wrong! Please try again.'
                    });
                }
            }).finally(()=>{
                this.removePromo = false;
            });
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>