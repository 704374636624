<template>
    <v-card class="elevation-2 mt-0" min-height="200" :loading="loading">
        <v-card-title class="card-header page">
            <v-icon color="primary" class="mr-2 ml-1">fa fa-file-invoice-dollar</v-icon> Your Invoices
        </v-card-title>
        <v-card-text class="px-1 pb-1">
            <v-row no-gutters class="mt-0" v-if="!loading">
                <v-col cols="12" md="12">
                      <v-data-table
                        :headers="headers"
                        :items="invoices"
                        :items-per-page="8"
                        class=""
                        mobile-breakpoint="0"
                    >
                        <template v-slot:item.date="{ item }">
                            <span class="subtitle-2 hidden-sm-and-down">{{ $moment.unix(item.created).tz(user.timezone).format('MMMM Do YYYY') }}</span>
                            <span class="subtitle-2 hidden-md-and-up">{{ $moment.unix(item.created).tz(user.timezone).format('MM/DD/YY') }}</span>
                        </template>
                        <template v-slot:item.amount="{ item }">
                            {{ getAmount(item.total) }}
                        </template>
                        <template v-slot:item.status="{ item }">
                        <span :class="(item.paid) ? 'success--text' : 'error--text'">{{ (item.paid) ? 'Paid' : 'Due' }}</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn color="primary" small text :href="item.hosted_invoice_url" target="_blank"><v-icon small class="mr-2">fa fa-external-link</v-icon> View</v-btn>
                            <v-btn color="primary" class="ml-2 d-none hidden-sm-and-down" small :href="item.invoice_pdf" text target="_blank"><v-icon small class="mr-2">fa fa-download</v-icon> PDF</v-btn>
                        </template>
                    </v-data-table>
                    
                    <!-- <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left">
                                Date
                            </th>
                            <th class="text-left">
                                Amount
                            </th>
                            <th class="text-left">
                                Status
                            </th>
                            <th class="text-right">
                                
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="item in invoices"
                            :key="item.id"
                            >
                            <td>{{ $moment.unix(item.created).tz(user.timezone).format('MMMM Do YYYY') }}</td>
                            <td>{{ getAmount(item.total) }}</td>
                            <td><span :class="(item.paid) ? 'success--text' : 'error--text'">{{ (item.paid) ? 'Paid' : 'Due' }}</span></td>
                            <td class="text-right">
                                <v-btn color="primary" class="mr-2" small icon :href="item.hosted_invoice_url" target="_blank"><v-icon>fa fa-external-link</v-icon></v-btn>
                                <v-btn color="primary" small icon :href="item.invoice_pdf" target="_blank"><v-icon>fa fa-download</v-icon></v-btn>
                            </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table> -->
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
    
import { mapState } from 'vuex'

export default {
    name: 'UserProfileInvoices',
    props: {
    },
    components: {
    },
    computed: {
        ...mapState(['user']),
        
    },
    data: () => ({
        loading: true,
        goingToPortal: false,
        headers: [
          {
            text: 'Date',
            align: 'start',
            sortable: false,
            value: 'date',
          },
          { text: 'Amount', value: 'amount', sortable: false },
          { text: 'Status', value: 'status', sortable: false },
          { text: 'Invoice', align: 'center', value: 'actions', sortable: false },
        ],
        invoices: [],
    }),
    methods: {
        initData(){
            this.ready = true;
            this.getSubscription();
        },
        getAmount(total){
            if(!total || total==0){
                return '$0.00';
            }
            let totalTotal = (total / 100);
            return "$" + totalTotal.toFixed(2);
        },
        getSubscription(){
			this.loading = true;
            let request = {
                endpoint: '/invoices', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
					this.invoices = res.data;
				}
            }).finally(()=>{
                this.loading = false;
            });
		},
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>